import { toggleClass, hasClass } from "../../js/utils/elements-class-utils";

const defaultSettings = {
    event: 'click',
    classList: {
        activeClass: 'is-active',
    },
    selectors: {
        trigger: '.js-accordion-trigger',
        body: '.js-accordion-body',
    },
};

export default class Accordion {
    constructor(
        selector,
        settings,
    ) {
        this._elements = document.querySelectorAll(selector);
        this._settings = { ...defaultSettings, ...settings };

        this.init();
    }

    init() {
        if (this._elements) {
            this._elements.forEach(el => {
                this.addEvents(el);
            })
        }
    }

    addEvents(element) {
        const { activeClass } = this._settings.classList;
        const { selectors, event } = this._settings;

        const triggerButton = element.querySelector(selectors.trigger);
        const accordionBody = element.querySelector(selectors.body);

        if (!triggerButton) {
            return;
        }

        triggerButton.addEventListener(event, () => {
            toggleClass(element, activeClass)

            if (hasClass(element, activeClass)) {
                accordionBody.style.maxHeight = accordionBody.scrollHeight + 'px';
            } else {
                accordionBody.style.maxHeight = 0;
            }

            triggerButton.blur()
        });

        // document.body.addEventListener(event, event => {
        //     if (!element.contains(event.target)) {
        //         removeClass(element, activeClass)
        //         accordionBody.style.maxHeight = 0;
        //     }
        // });
    }
}

import { removeClass, addClass, insertAfter, hasClass } from "../utils/elements-class-utils";
import Element from "../utils/element";

export default class FixedElement extends Element {
    _fixPoint = 0;
    constructor(
        selector,
        settings = {
            hasPlaceholder: true,
            classList: {
                onPlaceholderRender: 'js-fixed-el-abs',
                onScroll: 'js-fixed-el',
            },
        }
    ) {
        super(selector, settings);
        if (!this._element) {
            return
        }
        this._fixPoint = window.pageYOffset + this._element.getBoundingClientRect().top;
        this.height = this._element.offsetHeight;

        this.init();
    }

    init() {
        window.addEventListener('scroll', () => {
            const scrollPos = window.pageYOffset;
            const {
                classList,
            } = this._settings;

            if (scrollPos > this._fixPoint) {
                removeClass(this._element, classList.onPlaceholderRender);
                this.handleScrollMode();
            } else {
                removeClass(this._element, classList.onScroll);

                if (this.placeholder) {
                    this.resizeHandler();
                    addClass(this._element, classList.onPlaceholderRender);
                }
            }
        })
        this.addPlaceholderElement()
    }

    handleScrollMode() {
        const { classList } = this._settings;
        addClass(this._element, classList.onScroll);
    }

    addPlaceholderElement() {
        if (this._settings.hasPlaceholder) {
            const placeholder = document.createElement('div');
            placeholder.style.height = `${this.height}px`;
            insertAfter(placeholder, this._element);

            this.placeholder = placeholder;
            addClass(this._element, this._settings.classList.onPlaceholderRender);

            window.addEventListener('resize', () => {
                this.resizeHandler();
            })
        }
    }

    resizeHandler() {
        this._element.addEventListener('transitionend', () => {
            if (!hasClass(this._element, this._settings.classList.onScroll)) {
                this.height = this._element.offsetHeight;

                if (this.placeholder) {
                    this.placeholder.style.height = `${this.height}px`;
                }
            }
        });
    }
}

import FixedElement from "./components/fixed-element";
import MenuNav from "../components/menu-nav/menu-nav";
import Modal from "./components/fancybox";
import Slider from "./components/carousel";
import { toggleClass } from "./utils/elements-class-utils";
import Accordion from "./components/accordion";
import FakeSelect from "./components/fake-select";

const _fixedElement = new FixedElement('.js-fixed-element');
const _menuNav = new MenuNav('.js-menu-nav');
const _ = new Accordion('[data-accordion]');
const _modal = new Modal('[data-fancybox]', {
    compact: false,
    animated: false,
    dragToClose: false,
    autoFocus: false,
});
const _productModal = new Modal('[data-fancybox="product"]', {
    Hash: false,
    autoFocus: false,
    placeFocusBack: false,
})
const _fullWidthSlider = new Slider('.js-full-width-slider', {
    breakpoints: false,
    spaceBetween: 0,
    pagination: {
        el: '.js-full-width-slider .js-pagination',
        clickable: true,
        bulletClass: 'swiper__bullet',
        bulletActiveClass: 'is-active',
    },
    // autoplay: {
    //     delay: 5000,
    //     pauseOnMouseEnter: true,
    // },
})
const _categoriesSlider = new Slider('.js-product-categories-slider', {
    slidesPerView: 1.1,
    spaceBetween: 10,
    breakpoints: {
        768: {
            slidesPerView: 2.1,
            spaceBetween: 10,
        },
        1280: {
            slidesPerView: 3.1,
            spaceBetween: 10,
        },
    },
    pagination: false,
    // autoplay: {
    //     delay: 5000,
    //     pauseOnMouseEnter: true,
    // },
})
const _productMoreSlider = new Slider('.js-product-more-slider', {
    slidesPerView: 1.1,
    spaceBetween: 10,
    breakpoints: {
        768: {
            slidesPerView: 2.1,
        },
        1280: {
            slidesPerView: 3.1,
        },
        1920: {
            slidesPerView: 4.1,
        },
    },
    pagination: false,
    navigation: {
        nextEl: '.more-products__actions .swiper__action.is-next',
        prevEl: '.more-products__actions .swiper__action.is-prev',
        disabledClass: 'is-disabled',
    },
    autoplay: {
        delay: 5000,
        pauseOnMouseEnter: true,
    },
})
const _shopSlider = new Slider('.js-shop-slider', {
    breakpoints: false,
    spaceBetween: 0,
});
const _completeLookSlider = new Slider('.js-complete-look-slider', {
    breakpoints: {
        768: {
            slidesPerView: 4.1,
        },
        1280: {
            slidesPerView: 5,
        },
    },
    slidesPerView: 2.1,
    spaceBetween: 10,
});

const seoShowMore = document.querySelector('.seo .seo-show-more')
if (seoShowMore) {
    seoShowMore.addEventListener('click', e => {
        const content = e.target.closest('.seo').querySelector('.seo-content');
        toggleClass(e.target, 'is-active');
        toggleClass(content, 'is-active');
        if (content.style.maxHeight) {
            content.style.maxHeight = '';
        } else {
            content.style.maxHeight = 'none'
        }
    })
}

const button = document.querySelectorAll('[data-filter]');

if (button) {
    button.forEach(btn => {
        btn.addEventListener('click', e => {
            const { filter } = e.target.dataset;
            toggleClass(document.querySelector(filter), 'is-open');
            toggleClass(document.body, 'menu-opened');
        })
    })
}

const _fakeSelect = new FakeSelect('.js-fake-select');


import { hasClass, removeClass, toggleClass } from "../../js/utils/elements-class-utils";
import Element from "../../js/utils/element";

export default class MenuNav extends Element {
    constructor(selector, settings = {
        classList: {
            container: '.js-menu-nav-container',
            toggleButton: '.js-menu-nav-toggle',
            closeButton: '.js-menu-nav-close',
            visibleClass: 'is-open',
            activeButtonClass: 'is-active',
            subMenuButtons: '.menu-nav__item  .menu-nav__button',
        },
    }) {
        super(selector, settings);

        this.init();
    }

    init() {
        const { classList } = this._settings;
        this.container = document.querySelector(classList.container);
        this.toggleButton = document.querySelector(classList.toggleButton);
        this.closeButton = document.querySelectorAll(classList.closeButton);
        this.subMenusButtons = document.querySelectorAll(classList.subMenuButtons)

        if (!this.toggleButton || !this.closeButton) {
            return
        }

        this.addEvents();
    }

    // eslint-disable-next-line sonarjs/cognitive-complexity
    addEvents() {
        const { classList } = this._settings;
        this.toggleButton.addEventListener('click', () => {
            toggleClass(this.container, classList.visibleClass);
            toggleClass(document.body, 'menu-opened');
            toggleClass(this.toggleButton, classList.activeButtonClass);
        })

        this.closeButton.forEach(el => {
            el.addEventListener('click', () => {
                removeClass(this.container, classList.visibleClass);
                toggleClass(document.body, 'menu-opened');
                removeClass(document.querySelector('.menu-nav__submenu-block.is-active'), 'is-active')
            })
        })

        if (this.subMenusButtons) {
            this.subMenusButtons.forEach(el => {
                el.addEventListener('click', e => {
                    const { target } = e;
                    const closest = target.closest('.menu-nav__submenu-block');
                    const nextSibling = target.nextElementSibling;
                    toggleClass(target, 'is-active');
                    if (closest) {
                        removeClass(closest, 'is-active')
                    }
                    if (nextSibling) {
                        toggleClass(nextSibling, 'is-active')
                        if (window.innerWidth <= 767) {
                            if (hasClass(nextSibling, 'is-active')) {
                                nextSibling.style.maxHeight = nextSibling.scrollHeight + 'px';
                            } else {
                                nextSibling.style.maxHeight = 0;
                            }
                        }
                    }
                })
            })
        }
    }
}

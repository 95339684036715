export function addClass(element, className) {
    if (!element) {
        return
    }
    if (typeof className === 'string') {
        element.classList.add(className);
    } else {
        className.forEach(item => {
            removeClass(element, item);
        })
    }
}

export function removeClass(element, className) {
    if (!element) {
        return
    }
    if (typeof className === 'string') {
        element.classList.remove(className);
    } else {
        className.forEach(item => {
            removeClass(element, item);
        })
    }
}

export function hasClass(element, className) {
    if (!element) {
        return
    }
    return element.classList.contains(className);
}

export function toggleClass(element, className) {
    if (!element) {
        return
    }
    if (hasClass(element, className)) {
        removeClass(element, className);
    } else {
        addClass(element, className);
    }
}

export function insertAfter(newNode, refNode) {
    if (refNode && refNode.parentNode) {
        refNode.parentNode.insertBefore(newNode, refNode.nextSibling);
    }
}

// import { Fancybox } from "@fancyapps/ui";

export default class Modal {
    constructor(selector, settings = {
        compact: false,
        animated: false,
    }) {
        this.selector = selector;
        this.settings = settings;

        this.init();
    }

    init() {
        // eslint-disable-next-line
        Fancybox.bind(this.selector, this.settings);
    }
}

// import Swiper from "swiper";
// import { Navigation, Pagination, Parallax, Keyboard } from 'swiper/modules';

const defaultSettings = {
    // modules: [Navigation, Pagination, Parallax, Keyboard],
    loop: false,
    grabCursor: true,
    slidesPerView: 1,
    spaceBetween: 20,
    centeredSlides: false,
    breakpoints: {
        768: {
            slidesPerView: 2,
            spaceBetween: 20,
        },
        1280: {
            slidesPerView: 3,
            spaceBetween: 30,
        },
    },
    pagination: {
        el: '.js-pagination',
        clickable: true,
        bulletClass: 'swiper__bullet',
        bulletActiveClass: 'is-active',
    },
    navigation: {
        nextEl: '.swiper__action.is-next',
        prevEl: '.swiper__action.is-prev',
        disabledClass: 'is-disabled',
    },
    parallax: {
        enabled: false,
    },
    keyboard: {
      enabled: true,
    },
};

export default class Slider {
    #slider;
    constructor(selector, settings) {
        this.selector = selector;
        this.settings = { ...defaultSettings, ...settings };

        this.init();
    }

    init() {
        // eslint-disable-next-line
        this.#slider = new Swiper(this.selector, this.settings);
    }
}

export default class Element {
    _element = null;
    _settings = {};
    constructor(
        selector,
        settings = {}
    ) {
        this._element = document.querySelector(selector);
        this._settings = settings;
    }
}

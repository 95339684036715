import Element from "../utils/element";
import { addClass, hasClass, removeClass, toggleClass } from "../utils/elements-class-utils";

export default class FakeSelect extends Element {
    constructor(selector, settings = {
        button: '.js-fake-select-button',
        name: '.js-fake-select-name',
        body: '.js-fake-select-body',
        close: '.js-fake-select-close',
        classList: {
            active: 'is-active',
        },
    }) {
        super(selector, settings);
        this.select = this._element.querySelector('select');
        this.list = this._element.querySelector('.fake-select__list');
        this.name = this._element.querySelector(this._settings.name);
        this.body = this._element.querySelector(this._settings.body);
        this.button = this._element.querySelector(this._settings.button);
        this.close = this._element.querySelectorAll(this._settings.close);

        this.init()
    }

    init() {
        this.createFakeList()

        this.button.addEventListener('click', () => {
            this.toggleList();
        })

        this.close.forEach(el => {
            el.addEventListener('click', () => {
                this.closeList();
            })
        })
    }

    createFakeList() {
        Array.from(this.select.options).forEach(option => {
            const item = document.createElement('div')
            addClass(item, 'fake-select__item');
            item.innerHTML = option.text;
            item.setAttribute('data-value', option.value);
            if (option.value === '' || option.disabled) {
                addClass(item, 'disabled')
            }
            this.list.appendChild(item);
            item.addEventListener('click', e => {
                this.changeValue(e.target.dataset.value)
                this.closeList();
            })
        })
    }

    changeValue(value) {
        this.select.value = value;
        this.name.innerHTML = value;
    }

    toggleList() {
        const { active } = this._settings.classList;
        toggleClass(this.body, active);
        if (window.innerWidth > 767) {
            if (hasClass(this.body, active)) {
                this.body.style.maxHeight = this.body.scrollHeight + 'px';
            } else {
                this.body.style.maxHeight = 0;
            }
        } else {
            toggleClass(document.body, 'menu-opened');
        }
    }

    openList() {
        const { active } = this._settings.classList;
        addClass(this.body, active);
        if (window.innerWidth > 767) {
            this.body.style.maxHeight = this.body.scrollHeight + 'px';
        } else {
            addClass(document.body, 'menu-opened');
        }
    }

    closeList() {
        const { active } = this._settings.classList;
        removeClass(this.body, active);
        if (window.innerWidth > 767) {
            this.body.style.maxHeight = 0;
        } else {
            removeClass(document.body, 'menu-opened');
        }
    }
}
